import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoChatbubbleEllipsesOutline, IoPhonePortraitOutline, IoReorderThreeOutline, IoClose } from 'react-icons/io5';
import Main from './main.js'
import MoreDetails from './moreDetails.js';
import ClientApp from './clientApp.js'
import Partner from './partner.js'
import Price from './price.js'

function Home() {
  // Create refs for each section
  const mainRef = useRef(null);
  const moreDetailsRef = useRef(null);
  const clientAppRef = useRef(null);
  const priceRef = useRef(null);
  const partnerRef = useRef(null);
  const contactRef = useRef(null);
  const supportRef = useRef(null);

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  // Scroll to a section smoothly
  const scrollToSection = (ref) => {
    setIsOpen(false);
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToMoreDetails = () => {
    setIsOpen(false);
    moreDetailsRef?.current.scrollIntoView({ behavior: 'smooth' });
  };


  const goToAppHome = () => {
    navigate('/appHome');
  };

  return (
    <div>
      {/* Navigation */}
      <nav className="fixed w-full bg-bgTitle">
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex flex-shrink-0 items-center">
              <img
                src="https://smartapplications.com.br/images/logoaigarcom2.png"
                alt="Logo"
                className="h-6"
              />
            </div>

            {/* <div className='flex md:hidden items-center'>
              <button
                onClick={() => scrollToSection(mainRef)}
                className="cursor-pointer border border-white rounded hover:bg-gray-900 hover:text-gray-900 px-1 py-1 rounded-md"
              >
                <div className='flex flex-row text-xs items-center text-white'>
                  <IoPhonePortraitOutline className="h-5 w-5" />
                  Fazer pedido

                </div>
              </button>
            </div> */}

            <div className='flex md:hidden items-center'>
              <button
                onClick={() => goToAppHome()}
                className="cursor-pointer hover:bg-gray-900 hover:text-white rounded px-1 py-1"
              >
                <div className='flex flex-row text-xs items-center'>
                  <IoPhonePortraitOutline className="h-4 w-4" />
                  Abrir app cliente

                </div>
              </button>
            </div>

            <div className="hidden md:flex space-x-4">
              <button
                onClick={() => scrollToSection(mainRef)}
                className="cursor-pointer text-gray-700 hover:bg-gray-100 hover:text-gray-900 px-3 py-2 rounded-md"
              >
                Home
              </button>

              <button
                onClick={() => scrollToSection(moreDetailsRef)}
                className="cursor-pointer text-gray-700 hover:bg-gray-100 hover:text-gray-900 px-3 py-2 rounded-md"
              >
                Conheça o aplicativo
              </button>

              <button
                onClick={() => scrollToSection(priceRef)}
                className="cursor-pointer text-gray-700 hover:bg-gray-100 hover:text-gray-900 px-3 py-2 rounded-md"
              >
                Preço
              </button>

              <button
                onClick={() => scrollToSection(clientAppRef)}
                className="cursor-pointer text-gray-700 hover:bg-gray-100 hover:text-gray-900 px-3 py-2 rounded-md"
              >
                App do cliente
              </button>

              <button
                onClick={() => scrollToSection(partnerRef)}
                className="cursor-pointer text-gray-700 hover:bg-gray-100 hover:text-gray-900 px-3 py-2 rounded-md "
              >
                Quer ter um ganho extra?
              </button>

            </div>
            <div className="flex md:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-700 hover:text-gray-900 focus:outline-none"
              >
                {isOpen ? (
                  <IoClose className="h-6 w-6" />
                ) : (
                  <IoReorderThreeOutline className="h-6 w-6" />
                )}
              </button>
            </div>

          </div>
        </div>

        {isOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <button
                onClick={() => scrollToSection(mainRef)}
                className="block text-gray-700 hover:bg-gray-100 hover:text-gray-900 px-3 py-2 rounded-md text-base font-medium"
              >
                Home
              </button>

              <button
                onClick={() => scrollToSection(moreDetailsRef)}
                className="block text-gray-700 hover:bg-gray-100 hover:text-gray-900 px-3 py-2 rounded-md text-base font-medium"
              >
                Conheça o aplicativo
              </button>

              <button
                onClick={() => scrollToSection(priceRef)}
                className="block text-gray-700 hover:bg-gray-100 hover:text-gray-900 px-3 py-2 rounded-md text-base font-medium"
              >
                Preço
              </button>

              <button
                onClick={() => scrollToSection(clientAppRef)}
                className="block text-gray-700 hover:bg-gray-100 hover:text-gray-900 px-3 py-2 rounded-md text-base font-medium"
              >
                App do cliente
              </button>
              <button
                onClick={() => scrollToSection(partnerRef)}
                className="block text-gray-700 hover:bg-gray-100 hover:text-gray-900 px-3 py-2 rounded-md text-base font-medium"
              >
                Quer ter um ganho extra?
              </button>
            </div>
          </div>
        )}
      </nav>

      {/* Sections */}
      <div>
        <section ref={mainRef}  className="h-screen flex items-center bg-gray-200">
          <Main scrollToMoreDetails={scrollToMoreDetails}/>
        </section>

        <section ref={moreDetailsRef} className="h-[240vh] md:h-[130vh] flex items-center justify-center bg-white">
          <MoreDetails />
        </section>

        <section ref={priceRef} className="h-screen flex items-center pl-6 md:pl-0 md:justify-center bg-gray-200">
          <Price />
        </section>

        <section ref={clientAppRef} className="h-screen flex items-center justify-center bg-white-200">
          <ClientApp />
        </section>

        <section ref={partnerRef} className="h-screen flex items-center justify-center bg-gray-200">
          <Partner />
        </section>
        <div class="fixed bottom-4 right-4">
          <button class="bg-blue-500 text-white p-4 rounded-full shadow-lg hover:bg-bgTitle focus:outline-none">
          <IoChatbubbleEllipsesOutline className="h-7 w-7" />
          </button>
        </div>

      </div>
    </div>
  );
}

export default Home;