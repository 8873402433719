
// bgButton: '#2196F3', #F44336

// const SCREEN_HEIGHT = window.innerHeight;
// const SCREEN_WIDTH = window.innerWidth;

const colors = {
  bg: '#FFF176',
  bgTitle: '#FFA726',
  bgTabNav: '#FFA726',
  fontDark: 'black',
  fontLight: 'white',
  fontGray: 'gray',
  bgGreen: '#2ecc71', 
  bgButton: '#2196F3',
  bgTopButton: '#2196F3',
  borderButton: '#0D47A1',
};

const appStyles = { 
  title: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "transparent",
  },
  h4_b: {
    fontSize: 22,
    fontWeight: "bold",
    color: "black"
  },
  h4_w: {
    fontSize: 22,
    fontWeight: "bold",
    color: "white"
  },
  h4_r: {
    fontSize: 22,
    fontWeight: "bold",
    color: "red"
  },
  h5_w: {
    fontSize: 18,
    fontWeight: "bold",
    color: "white"
  },
  fnt_sm_w: {
    fontSize: 12,
    color: "white"
  },
  splasTextW: {
    color: 'white',
    fontSize: 40,
    fontWeight: 'bold'
  },
  splasTextB: {
    color: 'black',
    fontSize: 40,
    fontWeight: 'bold'
  },
  TitleText: {
    fontSize: 20,
    color: 'white',
  },
  TitleTextBold: {
    fontSize: 24,
    color: 'black'
  },
  regularText: {
    fontSize: 16,
    color: 'gray',
  },
  regularTextBlack: {
    fontSize: 16,
    color: 'black',
  },
  bigText: {
    fontSize: 22,
    color: 'gray',
  },
  regularTextBold: {
    fontSize: 16,
    color: 'gray',
    fontWeight: 'bold'
  },
  regularTextGreen: {
    fontSize: 16,
    color: '#489F83',
  },
  regularTextRed: {
    fontSize: 16,
    color: '#AB3339',
  },
  regularTextBoldGreen: {
    fontSize: 16,
    color: '#489F83',
    fontWeight: 'bold'
  },
  regularTextBoldRed: {
    fontSize: 16,
    color: '#AB3339',
    fontWeight: 'bold'
  },
  regularTextBoldBlue: {
    fontSize: 16,
    color: 'lightblue',
    fontWeight: 'bold'
  },
  smallText: {
    fontSize: 15,
    color: 'gray',
  },
  smallerText: {
    fontSize: 13,
  },
  smallTextBlue: {
    fontSize: 15,
    color: '#035087',
  },
  smallTextBold: {
    fontSize: 15,
    color: 'gray',
    fontWeight: 'bold'
  },
  card: {
    marginTop: 5,
    backgroundColor: 'white',
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 5
  },
  textInputStyle: {
    color: 'black',
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    paddingHorizontal: 10,
    borderRadius: 5,
  },

  disableButtonVendor: {

    backgroundColor: 'lightgray',
    borderColor: colors.fontGray,
    borderWidth: 1,
    borderRadius: 25,
    paddingVertical: 5,
    paddingHorizontal:10,
    marginHorizontal: 10
  },
  transparentButtonVendor: {
    borderColor: '#64B5F6',
    borderWidth: 1,
    borderRadius: 25,
    paddingHorizontal: 10,
    paddingVertical:5,
    marginHorizontal: 10
  },
  highlightButtonVendor: {
    backgroundColor: colors.bgButton,
    borderColor: colors.bgButton,
    borderWidth: 1,
    borderRadius: 25,
    paddingHorizontal: 10,
    paddingVertical:5,
    marginHorizontal: 10
  },
  transparentButtonBuyer: {
    borderColor: '#0D47A1',
    borderWidth: 1,
    borderRadius: 25,
    padding: 7,
    paddingLeft: 12
  },
  regularButton: {
    backgroundColor: colors.bgButton,
    height: 42,
    borderColor: '#0D47A1',
    borderWidth: 1,
    borderRadius: 25,
    paddingHorizontal: 10,
    justifyContent: 'center'
  },
  topButton: {
    backgroundColor: colors.bgButton,
    borderColor: '#0D47A1',
    borderWidth: 1,
    borderRadius: 10,
    paddingHorizontal: 10,
    paddingVertical: 5

  },
  toastButton: {
    backgroundColor: 'transparent',
    borderColor: 'white',
    borderWidth: 1,
    borderRadius: 25,
    paddingHorizontal: 10,
    paddingVertical: 5,
    height: 40
  },
  link: {
    color: colors.bgButton,
    textDecorationLine: 'underline',
  }  

}

export { appStyles, colors };
