import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import AppHeader from './appHeader';
import AppContext from '../services/AppContext';
import Header from './header';



function Home() {
  const { orderToPlace, newOrder, loggedUser, tabRef, vendorRef, locationRef } = useContext(AppContext);
  const [visible, setVisible] = useState(false);
  const [qrCodeInfo, setQrCodeInfo] = useState(null)
  const [location, setLocation] = useState(null);
  const [btnMsg, setBtnMsg] = useState(null);
  const [errModalVisible, setErrModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const nextAction = useRef("OpenMenu");

  const navigate = useNavigate();

  const goToAbout = () => {
    navigate('/about');
  };



  return (
    <div>
      <AppHeader />

      <Header title='Identifique o estabelecimento' name={vendorRef.current.vendorName} />
      <h1 className="text-3xl font-bold">Home Page</h1>
      <button onClick={goToAbout}>Go to About</button>
    </div>
  );

}

export default Home;