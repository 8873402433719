
import React from 'react';
import { colors } from '../styles/AppStyles';
import { IoStorefrontOutline } from 'react-icons/io5';

const Header = (props) => {
    // console.log('props.name', props.name);

    if (props.name != undefined) {
    }
    return (

        <div
            className="bg-gray-100 pl-2 py-1 flex justify-start"
            style={{ backgroundColor: colors.bgTitle }}
        >
            {props.name && (
                <div className="flex flex-row items-center">
                    <IoStorefrontOutline size={20} color={colors.fontGray} />
                    <span className="ml-1 text-gray-600">{props.name}</span>
                </div>
            )}
            <div className="flex ml-1 ">
                <span className="text-lg font-bold text-white">{props.title}</span>
            </div>
        </div>
    );

};

export default Header;
