import React, { useEffect, useState, useContext, useRef } from 'react';
import { colors } from '../styles/AppStyles';
import AppContext from '../services/AppContext';
import { useNavigate } from 'react-router-dom';
import { IoPersonOutline } from 'react-icons/io5';
import { apiUrl } from '../utils/config';

const AppHeader = () => {
    const { vendorRef, userNameHeader, setUserNameHeader } = useContext(AppContext);
    const userEmailRef = useRef('');
    const navigate = useNavigate();

    useEffect(() => {

//        navigate("/createUser");
        const userEmail = localStorage.getItem('cliUserEmail');

        if (!userEmail) {
            // console.log("userID é branco");
            navigate("/createUser");
        }
        else {
            userEmailRef.current = userEmail;
            // console.log("getUserdata", userEmail)
            // console.log("getUserdata", userEmailRef.current)
            userLogin()
        }


    }, [userNameHeader])

    useEffect(() => {

        console.log("Context vendorRef", vendorRef)
        userLogin();
    }, [])

    const userLogin = () => {
        const userData = {
            "UserEmail": userEmailRef.current,
            "UserPwd": userEmailRef.current.substring(0, 8),
        };

        const controller = new AbortController();
        const signal = controller.signal;

        const tOut = setTimeout(() => {
            controller.abort();
            clearTimeout(tOut);
        }, 10000);


        console.log("apiUrl",apiUrl);

        fetch(apiUrl + "UserLogin", {
            method: 'POST',

            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJWYWxpZGF0ZWQiOiJPSyIsIlVzZXJJRCI6IjMyODZlYWUzLWM2ZTMtNGZjZS1iNjFlLTUwYTBhODk1OGYyOSIsIlVzZXJOYW1lIjoicHJldXNlciIsIlVzZXJFbWFpbCI6InByZXVzZXJAc21hcnRhcHBsaWNhdGlvbnMuY29tIiwiVXNlclJvbGUiOiJQcmVVc2VyIiwiSXNFbWFpbFZlcmlmaWVkIjoiMCIsImlhdCI6MTcwMTY5ODUzNiwiZXhwIjoxNzMzMjM0NTM2fQ.77K7yN7MwuSSK-zdYZjsehHhzdXNmgxjN8w31mAYsYg`,
            },
            body: JSON.stringify(userData),
            signal,
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Ops! Erro de comunicação com a nuvem. Por favor, tente mais tarde!');
                }
                return response.json();
            })
            .then((json) => {
                console.log("json", json);

                if (json.Validated === "OK") {
                    setUserNameHeader(json.UserName);
                } else {
                    alert("Usuário ou senha inválidos!");
                }
            })
            .catch((error) => {
            });
    };


    return (
        <div className="flex flex-row h-10 pt-1 pl-2"
            style={{
                backgroundColor: colors.bgTitle
            }}>
            <div className="flex flex-row basis-1/2">
                <div className="shrink-0 space-x-1" >
                    <img
                        src="https://smartapplications.com.br/images/logoaigarcom2.png"
                        alt="Logo"
                        className="w-32"
                    />
                </div>
                <div className="text-xs">
                    <p>v1.0</p>
                </div>
            </div>

            <div className="basis-1/2" >

                <div className='flex flex-row justify-end mr-3'>
                    <IoPersonOutline size={18} color={colors.fontLight} />

                    <p className="text-sm ml-3 text-white">{userNameHeader}</p>
                </div>
            </div>
        </div>
    );
};

export default AppHeader;
