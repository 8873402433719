import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import partnerImg from '../images/partner.jpg'

// import { IoReorderThreeOutline, IoClose } from 'react-icons/io5';

function Main() {
    // const goToAppHome = () => {
    //   navigate('/appHome');
    // };

    return (
        <div>
            <p class="text-2xl md:text-6xl text-center text-gray-800 mb-2">
                Pague de acordo com o uso
            </p>
            <p class="text-base md:text-3xl text-center text-gray-500 mb-2">
                Experimente por um mês gratuitamente*
            </p>
            <div className="flex flex-col space-y-4 mt-6 md:flex-row md:space-x-4 md:space-y-0">
                <div class="w-[45vh] rounded-lg shadow-lg bg-white">
                    <div class="px-6 py-4">
                        <div class="font-bold text-xl mb-2 font-raleway">Versão light</div>
                        <p class="text-gray-700 text-base font-raleway">
                            Para estabelecimentos com até 500 pedidos mês.
                        </p>
                        <p class="text-gray-700 text-2xl font-raleway">
                            R$ 129,00 mês
                        </p>
                    </div>
                </div>
                <div class="w-[45vh] rounded-lg shadow-lg bg-white ">
                    <div class="px-6 py-4">
                        <div class="font-bold text-xl mb-2 font-raleway">Versão standard</div>
                        <p class="text-gray-700 text-base font-raleway">
                            Para estabelecimentos com até 3000 pedidos mês.
                        </p>
                        <p class="text-gray-700 text-2xl font-raleway">
                            R$ 179,00 mês
                        </p>
                    </div>
                </div>
                <div class="w-[45vh] rounded-lg shadow-lg bg-white">
                    <div class="px-6 py-4">
                        <div class="font-bold text-xl mb-2 font-raleway">Versão plus</div>
                        <p class="text-gray-700 text-base font-raleway">
                            Para estabelecimentos com mais de 3000 pedidos mês.
                        </p>
                        <p class="text-gray-700 text-2xl font-raleway">
                            R$ 229,00 mês
                        </p>
                    </div>
                </div>
            </div>
            <div className='mt-3 ml-4'>
                <p class="text-gray-700 text-xs font-raleway">
                    <span className='font-bold text-base'>*</span>
                    Nós acreditamos que você vai ficar muito satisfeito com o nosso serviço, mas se ao final do primeiro mês,
                    não quiser continuar com o AiGarçom, basta não realizar o pagamento.
                </p>
                <p class="text-gray-700 text-xs font-raleway">
                    O serviço será cancelado automaticamente, sem qualquer ônus.
                </p>
            </div>
        </div>

    );
}

export default Main;