
function getTimeStampLocal() {

    const dt = new Date();
    return (
        dt.getFullYear().toString() +
        zeroPad((dt.getMonth() + 1).toString(), 2) +
        zeroPad(dt.getDate().toString(), 2) +
        zeroPad(dt.getHours().toString(), 2) +
        zeroPad(dt.getMinutes().toString(), 2) +
        zeroPad(dt.getSeconds().toString(), 2)
    )
}

const timeStampToData = (timeStamp) => {
    return timeStamp.substring(6, 8) + "/" +
        timeStamp.substring(4, 6) + "/" +
        timeStamp.substring(0, 4);
};

const timeStampToDataTime = (timeStamp) => {

    if (!timeStamp) {
        return "";
    }

    return timeStamp.substring(6, 8) + "/" +
        timeStamp.substring(4, 6) + "/" +
        timeStamp.substring(0, 4) + " " +
        timeStamp.substring(8, 10) + ":" +
        timeStamp.substring(10, 12);

};

const moneyToDecimal = (value) => {

    if (value === "") {
        return 0.00;
    }

    value = value.replace("R$", "").replace(".", "");
    value = value.replace(",", ".");

    return value;

};

const decimalToMoney = (value) => {

    if (!value) {
        return "R$0,00";
    }

    if (value === "") {
        return "R$0,00";
    }

    if (value === undefined) {
        return "R$0,00";
    }
    try {
        value = value.toFixed(2).toString().replace(".", ",");
        value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    } catch { }


    return "R$" + value;

};

function zeroPad(num, places) { return String(num).padStart(places, '0') };

function getLocalDate() {

    const dt = new Date();
    return (
        zeroPad(dt.getDate().toString(), 2) + "/" +
        zeroPad((dt.getMonth() + 1).toString(), 2) + "/" +
        dt.getFullYear().toString()

    )
}

function getLocalDateTime() {

    const dt = new Date();
    return (
        zeroPad(dt.getDate().toString(), 2) + "/" +
        zeroPad((dt.getMonth() + 1).toString(), 2) + "/" +
        dt.getFullYear().toString() + " " +
        zeroPad(dt.getHours().toString(), 2) + ":" +
        zeroPad(dt.getMinutes().toString(), 2) + ":" +
        zeroPad(dt.getSeconds().toString(), 2)
    )
}

function getLocalTime() {

    const dt = new Date();
    return (
        zeroPad(dt.getHours().toString(), 2) + ":" +
        zeroPad(dt.getMinutes().toString(), 2) + ":" +
        zeroPad(dt.getSeconds().toString(), 2)
    )
}

const generateRandomNumber = () => {
    const min = 1000;
    const max = 9999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
};


const timeStampToDateTime = (timeStamp) => {

    if (!timeStamp) {
        return "";
    }

    return timeStamp.substring(6, 8) + "/" +
        timeStamp.substring(4, 6) + "/" +
        timeStamp.substring(0, 4) + " " +
        timeStamp.substring(8, 10) + ":" +
        timeStamp.substring(10, 12);

};

function isValidCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, ''); // Remove caracteres especiais

    if (cnpj.length !== 14) return false;

    // Elimina CNPJs inválidos conhecidos
    if (/^(\d)\1{13}$/.test(cnpj)) return false;

    // Valida os dígitos verificadores
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }

    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(0))) return false;

    tamanho += 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(1))) return false;

    return true;
}

export { timeStampToData, timeStampToDataTime, moneyToDecimal, decimalToMoney, getLocalDate, getLocalDateTime, getLocalTime, getTimeStampLocal, generateRandomNumber, timeStampToDateTime, isValidCNPJ };
