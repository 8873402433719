import { v4 as uuidv4 } from 'uuid';

// const LoggedUser = { "displayName": null, "email": "Fazer Login", "emailVerified": false, "isAnonymous": false, "metadata": { "creationTime": 1647105575325, "lastSignInTime": 1647105575325 }, "phoneNumber": null, "photoURL": null, "providerData": [[Object]], "providerId": "firebase", "tenantId": null, "uid": null }
const LoggedUser = { 
"userID": "", 
"userName": "", 
"email": "Fazer Login", 
"userRole": "", 
"isEmailVerified": "0", 
"isAnonymous": "0",
"userToken":"",
"verificationCode":"" }

// const Session = { "UniqueID": null, "LoggedUser": LoggedUser};


const GetNewID = () => {
  return uuidv4();
}

const splitQRCodeInfo = (qrCodeInfo) => {
  const splitUrlParams = qrCodeInfo.split('?');
  const downloadUrl = splitUrlParams[0];

  const splitParams = splitUrlParams[1].split('&');
  const vendorID = splitParams[0].split('=')[1];
  const locationID = splitParams[1].split('=')[1];

  //console.log("URL, Vendor, location", downloadUrl,vendorID,locationID);

  return { 'downloadUrl': downloadUrl, 'vendorID': vendorID, 'locationID': locationID }
}

const Order = null;

// {
//   "productID": "001" , 
//   "quantity" : 1, "price":0.00  
// }

const calcOrderTotals = (order) => {
  const orderItems = order.orderItems;
  var totalQuantity = 0;
  var totToPay = 0;
  orderItems.map((item) => {

    totalQuantity += item.quantity;
    totToPay += item.price;

  })

  order.totalQuantity = totalQuantity;
  order.totToPay = totToPay;

  return { "order": order };
};

const VendorRef = {
  "vendorID": "",
  "vendorName": "",
  "vendorCPFCNPJ": ""
}

const TabRef = {
  "tabNumber": "",
  "orderID": "",
  "statusID": ""
}

const calcOrderItemTotals = (order, productID) => {
  const orderItems = order.orderItems;
  var totalQuantity = 0;
  var itemQuantity = 0;
  var totToPay = 0;
  orderItems.map((item) => {
    if (productID == item.productID) {
      itemQuantity += item.quantity
    }

    totalQuantity += item.quantity;
    totToPay += item.price * item.quantity;

  })

  order.itemQuantity = itemQuantity;
  order.totalQuantity = totalQuantity;
  order.totToPay = totToPay;

  return { "order": order };
};

const getUniqueOrderItems = (orderItems) => {
  var newOrderItems = [];

  orderItems.map((item) => {

    const i = newOrderItems.findIndex(row => item.productID === row.productID);

    if (i == -1) {
      //insert item
      const toSave = {
        "orderID": item.orderID, "productID": item.productID,
        "quantity": item.quantity, "price": item.price, "totPrice": item.price * item.quantity,
        "productDescription": item.productDescription, "productCode": item.productCode, 
        "url": item.url, "itemObs" : item.itemObs
      };
      newOrderItems.push(toSave);
    }
    else {
      //update totals
      newOrderItems[i].quantity += item.quantity;
      newOrderItems[i].totPrice += item.price * item.quantity
    }
  })

  ////console.log(newOrderItems);

  return newOrderItems;
}


const getTextEmail = (name, code) => {
  return `
  <!DOCTYPE html>
  <html lang="pt-br">
  
  <head>
      <meta charset="UTF-8">
      <title>Verificação de Usuário</title>
      <style>
          body {
              font-family: Arial, sans-serif;
              line-height: 1.6;
              margin: 0;
              padding: 0;
              background-color: #f4f4f4;
              color: #333;
          }
  
          header {
              background-color: #FFEB3B;
              color: #fff;
              padding: 20px;
              text-align: center;
          }
  
          .validation {
              text-align: center;
          }
  
          .container {
              width: 80%;
              margin: auto;
              overflow: hidden;
              padding: 20px;
          }
  
          .destaque {
              background-color: #FFEB3B;
              /* Amarelo 500 */
              color: #333;
              font-weight: bold;
              padding: 5px 10px;
              border-radius: 5px;
              display: inline-block;
              text-align: center;
              margin-bottom: 10px;
          }
  
          .cta-button {
              display: inline-block;
              background-color: #007bff;
              color: #fff;
              padding: 10px 20px;
              text-decoration: none;
              border-radius: 5px;
              font-size: x-large;
              font-weight: bold;
          }
  
          footer {
              background-color: #333;
              color: #fff;
              text-align: center;
              padding: 10px 0;
              position: fixed;
              bottom: 0;
              width: 100%;
          }
  
          h1 {
              color: #333;
          }
      </style>

  </head>
  
  <body>
      <header>
          <h1>Seu Código de Validação - É hora de confirmar!</h1>
      </header>
  
      <div class="container">
          <p>Olá ${name}!</p>
          <p>Que bom te ver por aqui! Para garantir que é você mesmo, precisamos de um último passo de verificação. Aqui
              está o seu código de validação:</p>
          <div class="validation">
              <div class="cta-button" id="codigoValidacao">${code}</div>
              </br>
          </div>
          <p>É simples concluir a verificação:</p>
          <ol>
          <li>No app AiGarçom.</li>
          <li>Clique no nome do usuário no canto superior direito da tela.</li>
          <li>Escolha a opção <b>Verificar Usuário</b> no menu.</li>
          <li>Insira o código de validação.</li>
              <li>Clique em "<span class="destaque">Verificar</span>" e pronto!</li>
          </ol>
      </div>
  
      <footer>
          <p>&copy; 2023 aigarcom.com.br Todos os direitos reservados.</p>
      </footer>
  </body>
  
  </html>
  `
  
}
export { GetNewID, splitQRCodeInfo, calcOrderTotals, calcOrderItemTotals, Order, getUniqueOrderItems, LoggedUser, getTextEmail, TabRef, VendorRef };


