import React, {useRef, useState, useContext} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home';
import CreateUser from './components/createUser';
import AppHome from './components/appHome';
import StartNow from './components/startNow';

import { AppContextProvider } from './services/AppContext'
import { LoggedUser, TabRef, VendorRef } from './services/data';

function App() {
  const orderID = useRef('');
  const [qrCodeInfo, setQrCodeInfo] = useState(null);
  const newOrder = useRef(true);
  const orderToPlace = useRef(null);
  const locationRef = useRef(null);
  const loggedUser = useRef(LoggedUser)
  const [userNameHeader, setUserNameHeader] = useState("");
  const vendorRef = useRef(VendorRef);
  const tabRef = useRef(TabRef);

  return (
    <AppContextProvider value={{
      loggedUser,
      qrCodeInfo,
      setQrCodeInfo,
      orderID,
      orderToPlace,
      newOrder,
      locationRef,
      userNameHeader,
      setUserNameHeader,
      tabRef,
      vendorRef
    }}>

      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/createUser" element={<CreateUser />} />
          <Route path="/appHome" element={<AppHome />} />
          <Route path="/startNow" element={<StartNow/>} />
        </Routes>
      </Router>
    </AppContextProvider>
  );
}

export default App;
